<template>
  <div
    class="login_box"
    @click="selectShow = false"
    v-loading="$store.state.metaShow"
    :element-loading-text="$store.state.loginText"
  >
    <el-row type="flex" class="login">
      <div class="login_right">
        <div class="login_content" v-if="autoLoginType">
          <div class="login-header">
            <h2>
              {{ loginType === "phone" ? $t("phoneLogin") : $t("emailLogin") }}
            </h2>
            <chosse-net />
          </div>
          <div class="countries" v-if="loginType === 'phone'">
            <vue-country-intl
              v-model="phoneCode.dialCode"
              schema="popover"
              @onChange="handleCountryChange"
              search-input-placeholder=""
            >
              <div class="phoneCode" slot="reference">
                <div>
                  <!-- 选择国家 -->
                  {{ $t("selectCountry") }}
                  : +{{ phoneCode.dialCode }}
                  <span :class="'iti-flag ' + phoneCode.code"> </span>
                </div>
                <i class="iconfont icon-xia"> </i></div
            ></vue-country-intl>
          </div>
          <div
            class="input_box"
            :class="{ borderR: phoneShow === 3, borderY: phoneShow === 2 }"
          >
            <input
              type="text"
              :placeholder="
                loginType === 'phone' ? $t('inputPhone') : $t('inputEmail')
              "
              v-model="phone"
              autocomplete="off"
              readonly
              onfocus="this.removeAttribute('readonly');"
              @keyup.enter="subLogin"
              @blur="getBlur(loginType)"
              :class="{ red: phoneShow === 3 }"
              @focus="getfocus('phoneShow')"
            />
          </div>
          <!-- <div class="phoneTips" v-if="phoneTips" style="color: #fd4426">
            {{ $t("phoneTips") }}  @input="validataPhone"
          </div>
          <div class="emailTips" v-if="emailTips" style="color: #157bee">
            {{ $t("emailTips") }}
          </div> -->
          <div
            class="input_box"
            :class="{
              borderR: passWordShow === 3,
              borderY: passWordShow === 2,
            }"
          >
            <input
              :type="passWordType ? 'text' : 'password'"
              :placeholder="$t('inputPassword')"
              v-model="passWord"
              @keyup.enter="subLogin"
              autocomplete="off"
              readonly
              onfocus="this.removeAttribute('readonly');"
              @blur="getBlur('passWord')"
              :class="{ red: passWordShow === 3 }"
              @focus="getfocus('passWordShow')"
            />
            <div v-if="passWord">
              <img
                v-if="!passWordType"
                src="../assets/images/list_icon_eye_1@2x.png"
                alt=""
                @click="passWordType = !passWordType"
              />
              <img
                v-if="passWordType"
                src="../assets/images/list_icon_eye_2@2x.png"
                alt=""
                @click="passWordType = !passWordType"
              />
            </div>
          </div>
          <div
            class="input_box"
            :class="{
              borderR: yzmShow === 3 && YZMBtn,
              borderY: yzmShow === 2,
            }"
          >
            <input
              type="text"
              maxlength="6"
              :placeholder="$t('inputCode')"
              class="yzm"
              v-model="yzm"
              @blur="getBlur('yzmShow')"
              @keyup.enter="subLogin"
              :class="{ red: yzmShow === 3 && YZMBtn }"
              @focus="getfocus('yzmShow')"
            />
            <button
              :class="yzmBtnShow ? 'yzmShow' : ''"
              @click="getyzm()"
              :disabled="YZMBtn"
            >
              {{ !YZMBtn ? $t("codeTips") : yzmText }}
            </button>
          </div>
          <div class="type_button">
            <span @click="setLoginType()" class="blue">
              {{ loginType === "phone" ? $t("emailLogin") : $t("phoneLogin") }}
            </span>
            <span @click="gotoUrl('Matesign', $route.query)">
              <!-- 账号注册 -->
              {{ $t("signUp") }}
            </span>
          </div>
          <button
            class="login_button"
            :class="{ login_buttonS: phone && passWord && yzm && YZMBtn }"
            @click="subLogin()"
            :disabled="loginLoading"
            v-loading="loginLoading"
          >
            <!-- 登录 -->
            {{ $t("login") }}
          </button>
        </div>
        <div class="login_content" v-else>
          <h2>
            {{ $t("login") }}
          </h2>
          <div class="input_box" style="border: none">
            <span>
              {{ $t("userName") }}
              :
              {{
                userData.register == "email" ? userData.email : userData.phone
              }}
            </span>
          </div>
          <div class="input_box">
            <input
              type="password"
              :placeholder="$t('inputPassword')"
              @keyup.enter="speedyLogin"
              v-model="passWord"
            />
          </div>
          <button
            class="login_button"
            :class="{ login_buttonS: passWord }"
            @click="speedyLogin()"
            :disabled="loginLoading"
            v-loading="loginLoading"
          >
            <!-- 登录 -->
            {{ $t("login") }}
          </button>
          <span class="quit" @click="speedyQuit()">
            {{ $t("cancel") }}
          </span>
        </div>
        <span class="user_login">
          {{ $t("authorize5") }}
        </span>
      </div>
    </el-row>
  </div>
</template>

<script>
import countries from "@/common/js/countryList";
// import { loginVerification } from '@/api/api'
import { logincheck, loginVerification } from "@/api/authorizeLogin_api.js";
import { getEnCryptedMnemonic, getInviteCode } from "../api/api";
import { isVerifiedPhone, isVerifiedEmail } from "@/common/js/verification";
import utils from "../common/js/util";
import chosseNet from "../components/chosseNet.vue";
const RIPEMD128 = require("ripemd128-js");
const Bip39 = require("bip39");
const Mnemonic = require("bsv/mnemonic");
const bsv = require("bsv");
// import axios from 'axios'
// import CryptoJS from 'crypto-js'
export default {
  components: {
    "chosse-net": chosseNet,
  },
  data() {
    return {
      countries: countries,
      speedBtn: false,
      MetaLock: false,
      phoneCode: {
        code: "cn",
        name: "China (中国)",
        dialCode: 86,
        phoneFormat: "131 2345 6789",
      },
      loginType: "email",
      codeimg: "cn",
      selectShow: false,
      phone: "",
      phoneShow: false,
      passWordShow: false,
      passWord: "",
      yzm: "",
      yzmShow: false,
      yzmText: "",
      yzmBtnShow: false,
      loginLoading: false,
      autoLogin: false,
      timeNub: 60,
      timeC: "",
      // 登录信息
      token: "",
      pk2: "",
      did: "",
      wallet: "",
      userData: "",
      YZMBtn: false,
      passWordType: false,
      autoLoginType: false,
      // phoneTips: false,
      // emailTips: false,
    };
  },
  watch: {
    //  loginType: {
    //   handler() {
    //     this.phoneTips = false;
    //     this.emailTips = false;
    //   },
    // },
    phone() {
      if (this.phone) {
        this.yzmBtnShow = true;
      } else {
        this.yzmBtnShow = false;
      }
    },
    // '$store.state.metaShow': function () {
    //   // console.log(this.$store)
    //   let userMeta = this.$store.state.userMeta
    //   if (!this.$store.state.metaShow) {
    //     if (userMeta.showId && userMeta.infoTxId && userMeta.name) {
    //       // console.log('我进来了')
    //       if (this.$route.query.from) {
    //         this.$utils.getJWTtoken().then(res => {
    //           // console.log(res)
    //           let data = this.$route.query
    //           // console.log(data)
    //           this.$cookie.set('metaToken', res.data.jwtToken, 60 * 60 * 24 * 30)
    //           let userAddress = this.$utils.encrypt(this.$store.state.wallet.getTokenReceiveAddress(), this.pk2.slice(0, 32))
    //           this.$cookie.set('userAddress', userAddress, 60 * 60 * 24 * 30)
    //           localStorage.setItem('localuserData', JSON.stringify(this.userData))
    //           this.gotoUrl('authorize', data)
    //         })
    //       } else {
    //         setTimeout(() => {
    //           this.gotoUrl('index')
    //         }, 1000)
    //       }
    //     } else {
    //       return this.$message.error(this.$t('metaTxt'))
    //     }
    //   }
    // }
  },
  computed: {
    codetext() {
      return this.$t("codeTips");
    },
  },
  methods: {
    handleCountryChange(item) {
      console.log(item);
      this.phoneCode.code = item.iso2;
      this.phoneCode.dialCode = item.dialCode;
    },
    // validataPhone(e) {
    //   const phoneReg = /^[0-9]*$/;
    //   const emailReg =
    //     /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    //   let {
    //     target: { value },
    //   } = e;
    //   if (this.loginType === "phone") {
    //     if (phoneReg.test(value)) {
    //       this.phoneTips = false;
    //     } else {
    //       this.phoneTips = true;
    //     }
    //   } else {
    //     if (emailReg.test(value) || value === "") {
    //       this.emailTips = false;
    //     } else {
    //       this.emailTips = true;
    //     }
    //   }
    // },
    outLogin() {
      this.$store.state.userData = "";
      localStorage.clear();
      sessionStorage.clear();
      this.gotoUrl("login");
      location.reload();
    },
    // 重新生成 MEtaID,
    GetPhone(item) {
      this.phoneCode = item;
    },
    getfocus(type) {
      this[type] = 2;
    },
    getBlur(type) {
      if (!this[type] && type !== "") {
        this[type + "Show"] = 3;
      } else {
        this[type + "Show"] = 1;
      }
      if (type === "phone" && !isVerifiedPhone(this.phone)) {
        this.$message.error(this.$t("phoneErr"));
      } else if (type === "email" && !isVerifiedEmail(this.phone)) {
        this.$message.error(this.$t("emailErr"));
      }
    },
    setLoginType() {
      this.loginType === "phone"
        ? (this.loginType = "email")
        : (this.loginType = "phone");
      this.phone = "";
      this.passWord = "";
      this.yzm = "";
      this.$ShowMoney.noneMnemonic.setLoginType(this.loginType);
      // console.log(this.loginType)
    },
    setPhoneNub() {
      let phone = this.phone;
      if (this.phoneCode.dialCode !== 86 && this.loginType === "phone") {
        phone = this.phoneCode.dialCode + this.phone;
      }
      return phone;
    },
    getyzm() {
      if (!this.phone) {
        return this.$message.error(
          this.loginType === "phone"
            ? this.$t("phoneNull")
            : this.$t("emailNull")
        );
      }
      let re =
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,16}$/;
      if (this.loginType === "email" && !re.test(this.phone)) {
        return this.$message.error(this.$t("emaiErr"));
      }
      let phone =
        (this.phoneCode.dialCode !== 86 && this.loginType === "phone"
          ? "+"
          : "") + this.setPhoneNub();
      let params = {
        userType: this.loginType,
        isEnglish: this.$i18n.locale !== "zh",
      };
      this.YZMBtn = true;
      this.setYZMText();
      params.userType === "phone"
        ? (params.phone = phone)
        : (params.email = phone);
      loginVerification(params, "oauth")
        .then((data) => {
          // console.log(data)
          this.$message({
            message: this.$t("codeSuccess"),
            type: "success",
          });
        })
        .catch((err) => {
          this.clearYZMText();
          return this.$message.error(this.$t("Error") + err.message);
        });
    },
    setYZMText() {
      this.yzmText = this.timeNub + "s";
      if (this.timeNub > 0) {
        this.timeC = setTimeout(() => {
          --this.timeNub;
          this.yzmText = this.timeNub + "s";
          this.setYZMText();
        }, 1000);
      } else {
        this.YZMBtn = false;
        this.timeNub = 60;
        this.yzmText = this.codetext;
      }
    },
    clearYZMText() {
      if (this.timeC) {
        clearTimeout(this.timeC);
        this.YZMBtn = false;
        this.timeNub = 60;
        this.loginLoading = false;
        this.yzmText = this.$t("codeTips");
      }
    },
    subLogin() {
      if (this.loginLoading) {
        return this.$message.error(this.$t("discussWarning"));
      }
      if (this.phone && this.passWord && this.yzm) {
        this.loginLoading = true;
        let phone = this.setPhoneNub();
        let params = {
          type: 1,
          code: this.yzm,
          userType: this.loginType,
          password: this.$utils.encryptPassword(this.passWord),
        };
        params.userType === "phone"
          ? (params.phone = phone)
          : (params.email = phone);
        logincheck(params)
          .then(async (res) => {
            console.log(res, "用户数据");
            if (res.code === 601) {
              let token = "";
              if (res.token) {
                token = res.token;
                res["token"] = token;
                res["loginType"] = this.loginType;
                // 没数据返回，暂测试用
                // res.data.data["register"] = this.loginType;
                // res.data.data["phone"] = "";
                // res.data.data["email"] = this.phone;
                //
                this.$store.dispatch("userData", res);
              } else {
                this.clearYZMText();
                this.loginLoading = false;
                this.$message.warning("logincheck err");
                return false;
              }
              const phone = res.register == "email" ? res.email : res.phone;
              const _m = this.$utils.createMnemonicByPk2(
                phone,
                this.passWord,
                res.pk2,
                res.tag
              );
              const zeroAddress = this.$utils.creadZeroAddress(_m, res.tag);

              const checkResult = await this.$utils.checkPasswordAddress(
                zeroAddress,
                this.loginType,
                phone,
                this
              );
              if (checkResult) {
                this.$router.push({
                  name: "thirdSetPassword",
                  params: {
                    token: token,
                    search: location.search,
                    password: this.passWord,
                  },
                });
              } else {
                this.$message.error(this.$t("passWordErr"));
              }

              return false;
            }
            this.pk2 = res.pk2;
            this.userData = res;
            // this.userData.phone = phone;
            // console.log(this.userData.headers)
            // this.userData.register = res.data.data.register;
            // let re = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
            // console.log(re.test(phone))
            // re.test(phone) > 0
            //   ? (this.userData.email = phone)
            //   : (this.userData.phone = phone);
            // console.log(this.$ShowMoney.noneMnemonic.getHeader().accessKey)
            // 进行登录
            // if (this.pk2) {
            this.login();
            // }
          })
          .catch((err) => {
            this.loginLoading = false;
            this.yzmShow = 3;
            return this.$message.error(
              err?.message == "Incorect code"
                ? this.$t("yzmError")
                : err.message
            );
          });
        // 提示未填写的信息
      } else {
        let from = ["phone", "passWord", "yzm"];
        for (let i = 0; i < from.length; i++) {
          if (!this[from[i]]) {
            this[from[i] + "Show"] = 3;
          }
        }
        return this.$message.error(this.$t("submitTips"));
      }
    },
    alert(item) {
      window.alert(item);
    },
    createMasterHdPrivateKey(phone, password, serverHex, userFlag) {
      // 转换大数字，字符串，数字和缓冲区
      const ppBuffer = bsv.deps.Buffer(phone + "/" + password);
      const ppHex = bsv.crypto.Hash.sha256(ppBuffer).toString("hex");
      let hex;
      const rip128 = RIPEMD128.RIPEMD128;
      // 生成hax
      if (userFlag === "new") {
        hex = bsv.deps.Buffer.from(ppHex + serverHex, "hex");
        hex = rip128(hex.toString("hex")).toString();
      } else {
        hex = bsv.deps.Buffer.from(ppHex + serverHex);
        hex = bsv.crypto.Hash.sha256sha256(hex).toString("hex");
      }
      // 助记词
      const m = Bip39.entropyToMnemonic(hex);
      // 根据用户类型生成12助记词或24助记词
      const path = userFlag === "new" ? "m/44'/10001'/0'" : "m/44'/145'/0'";
      const puk = Mnemonic.fromString(m).toHDPrivateKey().deriveChild(path);
      return puk;
    },
    async login() {
      // let phone = this.setPhoneNub()
      // console.log(this.userData.register)
      // TODO:暂数据不合理需配合登录方式处理register
      // let re = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
      // const isTestnet = process.env.VUE_APP_NETWORK === "testnet";
      // if (re.test(this.userData.register)) {
      //   console.log("我进来了");
      //   this.userData.register = "email";
      //   this.$ShowMoney.noneMnemonic.setLoginType("email");
      // } else {
      //   this.userData.register = "phone";
      //   this.$ShowMoney.noneMnemonic.setLoginType("phone");
      // }
      // TODO: 暂存userData
      this.$store.state.userData = this.userData;
      localStorage.setItem("loginType", this.userData.register);
      let de_mnemonic = "";
      try {
        console.log(this.userData);
        const result = await getEnCryptedMnemonic({
          userType: this.userData.register,
          phone: this.userData.register == "phone" ? this.userData.phone : "",
          email: this.userData.register == "email" ? this.userData.email : "",
          token: this.userData.token,
        });
        console.log(result, "用户数据");
        if (result.code === 601) {
          let token = "";
          // if (result.data.result && result.data.result.token) {
          //   token = result.data.result.token;
          //   result.data.result["token"] = token;
          //   result.data.result["loginType"] = this.loginType;
          //   this.$store.dispatch("userData", result.data.result);
          // } else {
          //   this.clearYZMText();
          //   this.loginLoading = false;
          //   this.$message.warning("logincheck err");
          //   return false;
          // }
          const _phone =
            this.userData.register == "phone"
              ? this.userData.phone
              : this.userData.email;
          const _m = this.$utils.createMnemonicByPk2(
            _phone,
            this.passWord,
            this.userData.pk2,
            this.userData.tag
          );
          const zeroAddress = this.$utils.creadZeroAddress(
            _m,
            this.userData.tag
          );

          const checkResult = await this.$utils.checkPasswordAddress(
            zeroAddress,
            this.userData.register,
            _phone,
            this
          );
          if (checkResult) {
            this.$router.push({
              name: "thirdSetPassword",
              params: {
                token: token,
                search: location.search,
                password: this.passWord,
              },
            });
          } else {
            this.$message.error(this.$t("passWordErr"));
          }
          return false;
        }
        de_mnemonic = this.$utils.aesDescryptMem(
          result.enCryptedMnemonic,
          this.passWord
        );
        if (!de_mnemonic) {
          this.loginLoading = false;
          this.clearYZMText();
          return this.$message.error(this.$t("passWordErr"));
        }
        this.$utils
          .newWallet(
            { ppk: "", mnemonic: de_mnemonic },
            "login",
            "",
            result.enCryptedMnemonic
          )
          .then(async (r) => {
            const metanetSdk = r;
            if (metanetSdk.isGotMetaIdInfo && !metanetSdk.isMetaIdCompleted) {
              console.log("需要升级");
              metanetSdk.initMetaId({
                isMetasv: true,
                onSuccess: (res) => {
                  console.log("账号升级完成");
                },
                onError: (error) => {
                  console.error(error);
                  if (error.data && error.data.message) {
                    this.$message.error(error.data.message);
                  }
                },
              });
            }
          });
      } catch (err) {
        this.loginLoading = false;
        return this.$message.error(err.message || "getEnCryptedMnemonic error");
      }

      let address = "";
      try {
        address = bsv.Mnemonic.fromString(de_mnemonic)
          .toHDPrivateKey()
          .deriveChild(
            this.userData.tag == "new" ? "m/44'/10001'/0'" : "m/44'/145'/0'"
          )
          .deriveChild(0)
          .deriveChild(0)
          .privateKey.toAddress(
            this.$store.state.isTestnet
              ? bsv.Networks.testnet.name
              : bsv.Networks.mainnet.name
          )
          .toString();
      } catch (error) {
        this.$message.error(this.$t("Mnemonic_error"));
        return (this.loginLoading = false);
      }
      console.log(address);
      let that = this;
      // this.userData.loginType = this.loginType
      that.$store.state.userData = this.userData;
      that.$cookie.set(
        "userData",
        true,
        60 * 60 * 24 * 30,
        null,
        null,
        true,
        "None"
      );
      that.$cookie.set(
        "__Injector-time",
        true,
        60 * 60 * 24,
        null,
        null,
        true,
        "None"
      );
      let accessKey = this.$utils.encrypt(
        this.passWord,
        this.userData.pk2.slice(0, 32)
      );
      localStorage.setItem("__Secure-SSID", accessKey);
      console.log(accessKey, this.userData.pk2.slice(0, 32));
      // localStorage.setItem('localuserData', JSON.stringify(that.userData))
      console.log(
        this.$utils.decrypt(accessKey, this.userData.pk2.slice(0, 32))
      );
      // if (this.$store.state.loginShow) {
      //   // console.log('自动登录')
      //   localStorage.setItem('accessKey', accessKey)
      // } else {
      //   // console.log('非自动登录')
      //   sessionStorage.setItem('accessKey', accessKey)
      // }
      const inviteCodeRes = await getInviteCode();
      let userData = that.userData;
      if (inviteCodeRes.promotionCode) {
        userData = {
          ...userData,
          inviteCode: inviteCodeRes.promotionCode,
        };
      }
      localStorage.setItem("loginShow", this.$store.state.loginShow);
      localStorage.setItem("localuserData", JSON.stringify(userData));
      // this.getJWTtoken(address)
      this.getJWTtoken(address);
    },
    getJWTtoken(address) {
      this.$utils
        .getJWTtoken(address)
        .then((res) => {
          console.log("获得jwt", res);
          let data = this.$route.query;
          this.$store.state.metaShow = false;
          this.$cookie.set(
            "metaToken",
            res,
            60 * 60 * 24 * 30,
            null,
            null,
            true,
            "None"
          );
          localStorage.setItem("metaToken", res);
          let userAddress = this.$utils.encrypt(address, this.pk2.slice(0, 32));
          this.$cookie.set(
            "userAddress",
            userAddress,
            60 * 60 * 24 * 30,
            null,
            null,
            true,
            "None"
          );
          localStorage.setItem("localuserData", JSON.stringify(this.userData));
          this.gotoUrl("authorize", data);
        })
        .catch((err) => {
          console.log("cookie error", err);
          this.loginLoading = false;
          return this.$message.error(this.$t("Error") + err.message);
        });
    },
    getLoginType() {
      let token = this.$cookie.get("userData");
      let userData = JSON.parse(localStorage.getItem("localuserData"));
      if (!userData || !token) {
        console.log("删除密码");
        localStorage.clear();
        sessionStorage.clear();
        this.$cookie.remove("userData");
        this.autoLoginType = true;
      } else {
        this.userData = userData;
        let accessKey = localStorage.getItem("__Secure-SSID") || "";
        if (accessKey === "") {
          this.autoLoginType = false;
        }
      }
    },
    speedyLogin() {
      console.log("你好！");
      this.loginLoading = true;
      if (!this.passWord) {
        this.loginLoading = false;
        return this.$message(this.$t("submitTips"));
      } else {
        setTimeout(() => {
          // TODO:register 返回值不合理
          // if (this.userData.register == "email") {
          //   this.userData.register = this.userData.email;
          // } else if (this.userData.register == "phone") {
          //   this.userData.register = this.userData.phone;
          // }
          this.login();
        }, 50);
      }
    },
    speedyQuit() {
      localStorage.clear();
      sessionStorage.clear();
      this.passWord = "";
      this.phone = "";
      this.$cookie.remove("metaToken");
      this.$cookie.remove("userAddress");
      console.log("speedyQuit 删除密码");
      localStorage.removeItem("__Secure-SSID");
      localStorage.removeItem("metaToken");
      this.autoLoginType = true;
      this.$store.commit("logout");
      this.userData = null;
      this.loginLoading = false;
    },
  },
  destroyed() {
    clearInterval(this.timeC);
  },
  created() {
    this.yzmText = this.$t("codeTips");
    this.getLoginType();
    const invitationCode = this.$route.query.refCode;
    if (invitationCode) {
      localStorage.setItem("referCode", invitationCode);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/intl.css";
.login_box {
  width: 100vw;
  min-height: calc(100vh - 68px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f7fa;
}
.login {
  max-width: 720px;
  background: url("../assets/images/background.png") no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  display: flex;
  min-width: 600px;
  justify-content: center;
}
.user_login {
  display: block;
  text-align: center;
  color: #909399;
  font-size: 12px;
  margin-top: 40px;
}
.login_left {
  padding-left: 20px;
  h2 {
    margin-top: 22px;
    font-size: 45px;
    color: #fff;
  }
  h4 {
    font-size: 16px;
    margin-bottom: 25px;
    color: #bfc2cc;
    font-weight: normal;
  }
  p {
    font-size: 14px;
    color: #bfc2cc;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    i {
      width: 8px;
      height: 8px;
      background: rgba(234, 179, 0, 1);
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .app_downloads {
    display: flex;
    margin-top: 45px;
    a {
      width: 195px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.08);
      border: 1px solid rgba(255, 255, 255, 0.8);
      border-radius: 30px;
      margin-right: 22px;
      i {
        font-size: 22px;
        margin-right: 10px;
      }
    }
  }
}
.login_right {
  padding: 20px;
}
::v-deep .vue-country-intl .country-intl-input-wrap {
  border: unset;
}
::v-deep .vue-country-intl .country-intl-label {
  padding-left: 0;
  padding: 7px 16px;
  font-size: 14px;
}
.login_content {
  max-width: 390px;
  background: #fff;
  margin: 0 auto;
  min-width: 330px;
  border-radius: 17px;
  position: relative;
  padding: 50px;
  &::before {
    content: "";
    background: url("../assets/images/backgroun_item.png") no-repeat;
    width: 54px;
    height: 54px;
    position: absolute;
    right: -27px;
    bottom: 66px;
  }

  .login-header {
    display: flex;

    h2 {
      flex: 1;
    }
  }
  h2 {
    color: #303133;
    font-size: 26px;
    margin-bottom: 22px;
    font-family: PingFang SC;
  }
  .countries {
    height: 40px;
    font-size: 15px;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #bfc2cc;
    .phoneCode {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .select_phone {
      position: absolute;
      height: 300px;
      left: 0;
      top: 95%;
      z-index: 1;
      background: #fff;
      width: 100%;
      overflow: auto;
      padding: 0 20px;
      border-radius: 5px;
      border: 1px solid #bfc2cc;
    }
    .select_phone::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #fff;
      border-radius: 10px;
    }
    .select_phone::-webkit-scrollbar-track {
      box-shadow: none;
      /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.1);
    }
    .select_phone::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
      background-color: #aaa;
    }
    .item_phone {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 40px;
      line-height: 40px;
      .item_name {
        width: 60%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  .quit {
    text-align: center;
    display: block;
    margin-top: 10px;
    color: #606266;
    font-size: 14px;
    cursor: pointer;
  }
}
.input_box {
  min-height: 60px;
  font-size: 15px;
  line-height: 60px;
  width: 100%;
  border-bottom: 1px solid #bfc2cc;
  transition: 0.3s all ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 25px;
    height: 25px;
    vertical-align: middle;
  }
  .red {
    &::-webkit-input-placeholder {
      color: #f56c6c;
    }
    &:-moz-placeholder {
      color: #f56c6c;
    }
    &::-moz-placeholder {
      color: #f56c6c;
    }
    &:-ms-input-placeholder {
      color: #f56c6c;
    }
  }
  input {
    height: 59px;
    font-size: 15px;
    color: #303133;
    border: none;
    display: block;
    outline: none;
    width: 100%;
  }
  .yzm {
    width: unse t;
  }
  button {
    font-size: 12px;
    color: #f9f9f9;
    width: 120px;
    cursor: pointer;
    height: 36px;
    line-height: 36px;
    background: rgba(191, 194, 204, 1);
    border-radius: 5px;
  }
  .yzmShow {
    background: #eab300;
  }
}
.borderY {
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid #eab300;
}
.borderR {
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid #f56c6c;
}
.type_button {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
  span {
    cursor: pointer;
    color: #606266;
    font-size: 15px;
  }
  .blue {
    color: #157bee;
  }
}
.auto_login {
  margin-top: 20px;
  font-size: 16px;
}
.login_button {
  cursor: pointer;
  max-width: 270px;
  width: 100%;
  height: 60px;
  background: rgba(191, 194, 204, 1);
  border-radius: 12px;
  color: #fff;
  font-size: 24px;
  line-height: 60px;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}
.login_buttonS {
  background: #eab300;
}
::v-deep .el-loading-spinner .path {
  stroke: #eab300;
}
@media only screen and (max-width: 768px) {
  .login_left {
    height: calc(100vh - 80px);
  }
  .login_right {
    padding-bottom: 60px;
  }
  .login_left .app_downloads a {
    font-size: 12px;
  }
}
@media only screen and (max-width: 450px) {
  .login_content {
    max-width: 330px;
  }
}
</style>
