var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.$store.state.metaShow,
          expression: "$store.state.metaShow",
        },
      ],
      staticClass: "login_box",
      attrs: { "element-loading-text": _vm.$store.state.loginText },
      on: {
        click: function ($event) {
          _vm.selectShow = false
        },
      },
    },
    [
      _c("el-row", { staticClass: "login", attrs: { type: "flex" } }, [
        _c("div", { staticClass: "login_right" }, [
          _vm.autoLoginType
            ? _c("div", { staticClass: "login_content" }, [
                _c(
                  "div",
                  { staticClass: "login-header" },
                  [
                    _c("h2", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.loginType === "phone"
                              ? _vm.$t("phoneLogin")
                              : _vm.$t("emailLogin")
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("chosse-net"),
                  ],
                  1
                ),
                _vm.loginType === "phone"
                  ? _c(
                      "div",
                      { staticClass: "countries" },
                      [
                        _c(
                          "vue-country-intl",
                          {
                            attrs: {
                              schema: "popover",
                              "search-input-placeholder": "",
                            },
                            on: { onChange: _vm.handleCountryChange },
                            model: {
                              value: _vm.phoneCode.dialCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.phoneCode, "dialCode", $$v)
                              },
                              expression: "phoneCode.dialCode",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "phoneCode",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("selectCountry")) +
                                      "\n                : +" +
                                      _vm._s(_vm.phoneCode.dialCode) +
                                      "\n                "
                                  ),
                                  _c("span", {
                                    class: "iti-flag " + _vm.phoneCode.code,
                                  }),
                                ]),
                                _c("i", { staticClass: "iconfont icon-xia" }),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "input_box",
                    class: {
                      borderR: _vm.phoneShow === 3,
                      borderY: _vm.phoneShow === 2,
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.phone,
                          expression: "phone",
                        },
                      ],
                      class: { red: _vm.phoneShow === 3 },
                      attrs: {
                        type: "text",
                        placeholder:
                          _vm.loginType === "phone"
                            ? _vm.$t("inputPhone")
                            : _vm.$t("inputEmail"),
                        autocomplete: "off",
                        readonly: "",
                        onfocus: "this.removeAttribute('readonly');",
                      },
                      domProps: { value: _vm.phone },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.subLogin.apply(null, arguments)
                        },
                        blur: function ($event) {
                          return _vm.getBlur(_vm.loginType)
                        },
                        focus: function ($event) {
                          return _vm.getfocus("phoneShow")
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.phone = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "input_box",
                    class: {
                      borderR: _vm.passWordShow === 3,
                      borderY: _vm.passWordShow === 2,
                    },
                  },
                  [
                    (_vm.passWordType ? "text" : "password") === "checkbox"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.passWord,
                              expression: "passWord",
                            },
                          ],
                          class: { red: _vm.passWordShow === 3 },
                          attrs: {
                            placeholder: _vm.$t("inputPassword"),
                            autocomplete: "off",
                            readonly: "",
                            onfocus: "this.removeAttribute('readonly');",
                            type: "checkbox",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.passWord)
                              ? _vm._i(_vm.passWord, null) > -1
                              : _vm.passWord,
                          },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.subLogin.apply(null, arguments)
                            },
                            blur: function ($event) {
                              return _vm.getBlur("passWord")
                            },
                            focus: function ($event) {
                              return _vm.getfocus("passWordShow")
                            },
                            change: function ($event) {
                              var $$a = _vm.passWord,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.passWord = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.passWord = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.passWord = $$c
                              }
                            },
                          },
                        })
                      : (_vm.passWordType ? "text" : "password") === "radio"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.passWord,
                              expression: "passWord",
                            },
                          ],
                          class: { red: _vm.passWordShow === 3 },
                          attrs: {
                            placeholder: _vm.$t("inputPassword"),
                            autocomplete: "off",
                            readonly: "",
                            onfocus: "this.removeAttribute('readonly');",
                            type: "radio",
                          },
                          domProps: { checked: _vm._q(_vm.passWord, null) },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.subLogin.apply(null, arguments)
                            },
                            blur: function ($event) {
                              return _vm.getBlur("passWord")
                            },
                            focus: function ($event) {
                              return _vm.getfocus("passWordShow")
                            },
                            change: function ($event) {
                              _vm.passWord = null
                            },
                          },
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.passWord,
                              expression: "passWord",
                            },
                          ],
                          class: { red: _vm.passWordShow === 3 },
                          attrs: {
                            placeholder: _vm.$t("inputPassword"),
                            autocomplete: "off",
                            readonly: "",
                            onfocus: "this.removeAttribute('readonly');",
                            type: _vm.passWordType ? "text" : "password",
                          },
                          domProps: { value: _vm.passWord },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.subLogin.apply(null, arguments)
                            },
                            blur: function ($event) {
                              return _vm.getBlur("passWord")
                            },
                            focus: function ($event) {
                              return _vm.getfocus("passWordShow")
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.passWord = $event.target.value
                            },
                          },
                        }),
                    _vm.passWord
                      ? _c("div", [
                          !_vm.passWordType
                            ? _c("img", {
                                attrs: {
                                  src: require("../assets/images/list_icon_eye_1@2x.png"),
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.passWordType = !_vm.passWordType
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.passWordType
                            ? _c("img", {
                                attrs: {
                                  src: require("../assets/images/list_icon_eye_2@2x.png"),
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.passWordType = !_vm.passWordType
                                  },
                                },
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "input_box",
                    class: {
                      borderR: _vm.yzmShow === 3 && _vm.YZMBtn,
                      borderY: _vm.yzmShow === 2,
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.yzm,
                          expression: "yzm",
                        },
                      ],
                      staticClass: "yzm",
                      class: { red: _vm.yzmShow === 3 && _vm.YZMBtn },
                      attrs: {
                        type: "text",
                        maxlength: "6",
                        placeholder: _vm.$t("inputCode"),
                      },
                      domProps: { value: _vm.yzm },
                      on: {
                        blur: function ($event) {
                          return _vm.getBlur("yzmShow")
                        },
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.subLogin.apply(null, arguments)
                        },
                        focus: function ($event) {
                          return _vm.getfocus("yzmShow")
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.yzm = $event.target.value
                        },
                      },
                    }),
                    _c(
                      "button",
                      {
                        class: _vm.yzmBtnShow ? "yzmShow" : "",
                        attrs: { disabled: _vm.YZMBtn },
                        on: {
                          click: function ($event) {
                            return _vm.getyzm()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              !_vm.YZMBtn ? _vm.$t("codeTips") : _vm.yzmText
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticClass: "type_button" }, [
                  _c(
                    "span",
                    {
                      staticClass: "blue",
                      on: {
                        click: function ($event) {
                          return _vm.setLoginType()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.loginType === "phone"
                              ? _vm.$t("emailLogin")
                              : _vm.$t("phoneLogin")
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.gotoUrl("Matesign", _vm.$route.query)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("signUp")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loginLoading,
                        expression: "loginLoading",
                      },
                    ],
                    staticClass: "login_button",
                    class: {
                      login_buttonS:
                        _vm.phone && _vm.passWord && _vm.yzm && _vm.YZMBtn,
                    },
                    attrs: { disabled: _vm.loginLoading },
                    on: {
                      click: function ($event) {
                        return _vm.subLogin()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("login")) + "\n        "
                    ),
                  ]
                ),
              ])
            : _c("div", { staticClass: "login_content" }, [
                _c("h2", [
                  _vm._v(
                    "\n          " + _vm._s(_vm.$t("login")) + "\n        "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "input_box", staticStyle: { border: "none" } },
                  [
                    _c("span", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("userName")) +
                          "\n            :\n            " +
                          _vm._s(
                            _vm.userData.register == "email"
                              ? _vm.userData.email
                              : _vm.userData.phone
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "input_box" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.passWord,
                        expression: "passWord",
                      },
                    ],
                    attrs: {
                      type: "password",
                      placeholder: _vm.$t("inputPassword"),
                    },
                    domProps: { value: _vm.passWord },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.speedyLogin.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.passWord = $event.target.value
                      },
                    },
                  }),
                ]),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loginLoading,
                        expression: "loginLoading",
                      },
                    ],
                    staticClass: "login_button",
                    class: { login_buttonS: _vm.passWord },
                    attrs: { disabled: _vm.loginLoading },
                    on: {
                      click: function ($event) {
                        return _vm.speedyLogin()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("login")) + "\n        "
                    ),
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "quit",
                    on: {
                      click: function ($event) {
                        return _vm.speedyQuit()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("cancel")) + "\n        "
                    ),
                  ]
                ),
              ]),
          _c("span", { staticClass: "user_login" }, [
            _vm._v("\n        " + _vm._s(_vm.$t("authorize5")) + "\n      "),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }